.main {
    background-color: #1A1B21;
    width: 100%;
    height: 90%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-pic {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.name-designation {
    width: 100%;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto 0;
}

.name, .designation, .website {
    display: block;
}

.name {
    font-size: 30px;
    font-weight: 700;
}

.designation {
    color: #feb561;
    font-size: 12px;
}

.website {
    color: #fff;
    font-size: 12px;
    margin-top: 12px;
    text-decoration: none;
}

.buttons, .about-other {
    width: 80%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.buttons a {
    text-decoration: none;
    color: inherit;
}

.buttons button {
    font-size: 13px;
    width: 45%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 10px;
}

.email-button {
    padding: 0 33px;
    background-color: #fff;
}

.linkedin-button {
    padding: 0 25px;
    background-color: #5093E2;
    color: #fff;
}

.buttons button img {
    width: 15px;
}

.about-other {
    color: #fff;
    margin: auto 0;
    line-height: 1.5;
}

.about .header, .interests .header {
    font-size: 20px;
    font-weight: 700;
}

.interests {
    margin-top: 15px;
}

.about .paragraph, .interests .paragraph {
    font-size: 10px;
    font-weight: 100;
}

.about-other {
    padding-bottom: 12.5px;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .name {
        font-size: 70px;
    }
    
    .designation {
        font-size: 30px;
    }
    
    .website {
        font-size: 30px;
        margin-top: 20px;
    }
    
    .buttons {
        margin-top: 50px;
    }
    
    .buttons button {
        font-size: 32px;
        width: 47%;
        height: 75px;
    }

    .email-button {
        padding: 0 70px;
    }
    
    .linkedin-button {
        padding: 0 50px;
    }
    
    .buttons button img {
        width: 35px;
    }
    
    .about .header, .interests .header {
        font-size: 40px;
    }
    
    .interests {
        margin-top: 30px;
    }
    
    .about .paragraph, .interests .paragraph {
        font-size: 21px;
    }
    
    .about-other {
        padding-bottom: 25px;
    }
}

@media (min-width: 1024px) and (max-width: 1700px) {
    .name {
        font-size: 25px;
    }
    
    .designation {
        font-size: 11px;
    }
    
    .website {
        font-size: 11px;
        margin-top: 10px;
    }
    
    .buttons {
        margin-top: 20px;
    }
    
    .buttons button {
        font-size: 12px;
        width: 47%;
        height: 32px;
    }

    .email-button {
        padding: 0 25px;
    }
    
    .linkedin-button {
        padding: 0 18px;
    }
    
    .buttons button img {
        width: 15px;
    }
    
    .about .header, .interests .header {
        font-size: 15px;
    }
    
    .interests {
        margin-top: 15px;
    }
    
    .about .paragraph, .interests .paragraph {
        font-size: 7px;
    }
    
    .about-other {
        padding-bottom: 12px;
    }
}
