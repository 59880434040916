.footer {
    background-color: #161619;
    width: 100%;
    height: 10%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 65%;
    display: flex;
    justify-content: space-between;
}

.container svg {
    width: 30px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container svg {
        width: 70px;
    }
}

@media (min-width: 1024px) and (max-width: 1700px) {
    .container svg {
        width: 28px;
    }
}