.App {
    background-color: #1A1B21;
    width: 18vw;
    height: 90vh;
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 25px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .App {
        width: 78vw;
        height: 88vh;
    }
}